import {debounce, visibleElement} from '@/pages/search/helpers'
import {MeiliSearch} from 'meilisearch'
import {getInputValue, setInputValue, getLanguage} from '@/utility/helper'
import "@/../css/pages/search.css";

let API_KEY: string | undefined = undefined
let HOST: string | undefined = undefined
import "./features/meilisearch.key-control"
import {fetchIcon, getFilters, getIconSearch} from "@/modules/meilisearch/helpers";
import {i18nProperty} from "@/modules/translations/i18n.property";

i18nProperty.locale = getLanguage();

enum INPUT_IDS {
  API = 'meilisearch_apiKey',
  HOST = 'meilisearch_host',
  HITS_DROPDOWN = 'meili_hits',
  TENURE = 'filter_tenure',
  SEARCH_BOX = 'searchbox',
  FILTER_LOCATION = 'filter_location',
  COUNTRY_CODE = 'country_code',
  FILTER_LOCATION_TYPE = 'filter_location_type',
}

enum INPUT_CLASS {
  CLEAR_SEARCHBOX_BUTTON = 'clear_searchbox_button',
}

// show or hide Hits dropdown 
const toggleDropdown = (isShow: boolean, idElement: string = INPUT_IDS.SEARCH_BOX) => {
  const meiliHits = document.getElementById(`meili-hits-${idElement}`) as HTMLInputElement
  if (!meiliHits) return;

  meiliHits.innerHTML = ''
  if (isShow) {
    meiliHits.classList.remove('hidden')
  } else {
    meiliHits.classList.add('hidden')
  }
}

export type TypeSearch = 'province' | 'no-province' | 'project' | ''; // or place
// show location dropdown after fetching results
const onSearchLocations = async (
  idElement: string = INPUT_IDS.SEARCH_BOX,
  typeFilter: string = '',
  redirectProject: boolean = true,
  redirectLocation: boolean = false,
) => {
  const searchValue = getInputValue(idElement)
  if (!HOST || !API_KEY) {
    return
  }

  // search by current locale
  const countryCodeEle = document.getElementById(INPUT_IDS.COUNTRY_CODE) as HTMLInputElement
  let countryCode = 'th'
  if (countryCodeEle) {
    countryCode = countryCodeEle?.value.toLowerCase()
  }

  let filter = getFilters(countryCode, typeFilter)
  const meilisearch = new MeiliSearch({host: HOST, apiKey: API_KEY});
  const searchIndex = meilisearch.index('locations')
  const results = await searchIndex.search(searchValue, {
    attributesToHighlight: ['en', 'th', 'ar'],
    highlightPreTag: '<b>',
    highlightPostTag: '</b>',
    filter: filter
  })

  // hide current location dropdown
  visibleElement(`meili-default-location-hits-${idElement}`, false);
  // generate dropdown after fetching results
  await generateDropdown(searchValue, results, idElement, redirectProject, redirectLocation)
}

// generate dropdown after fetching results
async function generateDropdown(
  searchValue: string,
  results: { hits: any[] } | undefined,
  idElement: string = INPUT_IDS.SEARCH_BOX,
  redirectProject: boolean = true,
  redirectLocation: boolean = false
) {
  const meiliHits = document.getElementById(`meili-hits-${idElement}`) as HTMLInputElement
  if (!meiliHits) return;

  let dropdown = ''

  if (!results?.hits?.length) {
    dropdown = '<p id="search-no-result" class="p-3"><b>' + searchValue +
      '</b> doesn’t match any locations. Try to search by province, city or district, or use the map to drill down your search.</p>'
  } else {
    const language = getLanguage()
    let index = 0;

    for (const result of results.hits) {
      let {
        type, sub_type, slug, location_1, location_2, location_3, full_slug, id
      } = result;
      let is_place = type === "place";
      let is_project = type === "project";
      let location_label = is_project ? (result[`location_${language}` ?? result['location_en']]) : ''; // using only project type
      let item_full_slug = is_place ? `${sub_type}/${slug}` : (full_slug ?? '');
      let locationCheck = (location: string) => location?.length ? location : '';
      const locationObj = {
        id: id,
        tenure: getInputValue(INPUT_IDS.TENURE),
        language,
        full_slug: item_full_slug,
        location_1: locationCheck(location_1),
        location_2: locationCheck(location_2),
        location_3: locationCheck(location_3),
        search_label: result[language] ?? result['en'],
        place: is_place ? slug : '',
        type: type,
        place_type: is_place && sub_type ? sub_type : '',
        translation_sub_type: is_place
          ? sub_type
          : i18nProperty.t(sub_type.toLowerCase(), {defaultValue: sub_type}),
        url: is_project ? (result[`url_${language}`] ?? result['url_en']) : '', // using only project type
        sub_type: sub_type,
      }

      const dropdownItem = await createDropdownItem(
        index,
        locationObj,
        location_label,
        idElement,
        redirectProject,
        redirectLocation)
      dropdown += dropdownItem.innerHTML;
      index++;
    }
  }
  toggleDropdown(true, idElement)
  meiliHits.innerHTML = dropdown;
  // if isset input value, show dropdown
  if (!getInputValue(idElement).length) {
    meiliHits.classList.add('hidden');
  }
  // Set the first element as selected
  meiliHits.scrollTo(0, 0);
}

async function createDropdownItem(
  index: number,
  locationObj: any,
  location_label: string,
  idElement: string = INPUT_IDS.SEARCH_BOX,
  redirectProject: boolean = true,
  redirectLocation: boolean = false
) {
  const dropdownItem = document.createElement('div') as HTMLDivElement
  let encodedData = JSON.stringify(locationObj).replace(/"/g, '&quot;')
  let icon = await fetchIcon(locationObj?.type, locationObj?.sub_type);
  dropdownItem.innerHTML = `<div id="dropdown-${index}" onclick="window.onLocationSelected(${encodedData},'${idElement}', ${redirectProject}, ${redirectLocation})"
    class="dropdown-item ${index === 0 ? 'dropdown-selected' : ''} flex gap-1 px-3 py-3 border-b cursor-pointer">
      ${icon ? icon : ''}
        <div class="w-full">
          <div class="flex gap-3 justify-between items-center">
            <div class="flex gap-2 pl-2">
              ${locationObj.search_label}
            </div>
            ${locationObj.translation_sub_type?.length
              ? `<div class="text-xs text-gray-400 uppercase pl-2">${locationObj.translation_sub_type}</div>`
              : ``}
          </div>
          ${location_label.length ? `<div class="text-xs md:text-sm text-gray-400 pl-2">${location_label}</div>` : ``}
        </div>
    </div>`;

  return dropdownItem;
}

// reset value and hide dropdown
const clearSearchBox = (idElement: string = INPUT_IDS.SEARCH_BOX, cb?: () => void) => {
  setInputValue(idElement, "");
  setInputValue(idElement+'_'+INPUT_IDS.FILTER_LOCATION, "");
  setInputValue(idElement+'_'+INPUT_IDS.FILTER_LOCATION_TYPE, "");
  toggleDropdown(false, idElement);
  // do callback logic for each page
  if (cb) cb();
}

//let debounceSearch = () => debounce(onSearchLocations, 500);
// on search box update event
//eventListener(document.getElementById(INPUT_IDS.SEARCH_BOX), 'keyup', keyupHandler);

export const keyupHandlerMeiliSearch = (
  idElement: string,
  redirectProject: boolean = true,
  redirectLocation: boolean = false,
  typeFilter = '',
) => {
  const e = window.event as KeyboardEvent;
  //console.log('keyupHandlerMeiliSearch', e)
  const skipKeys = ["ArrowDown", "ArrowUp", "ArrowLeft", "ArrowRight", "Enter"]
  if (skipKeys.includes(e.code)) {
    return;
  }
  const searchValue = getInputValue(idElement)
  if (!searchValue.length) {
    clearSearchBox(idElement)
    // show default location dropdown when input is empty
    visibleElement(`meili-default-location-hits-${idElement}`, true);
    return
  }
  debounce(onSearchLocations(idElement, typeFilter, redirectProject, redirectLocation), 500)
};

(() => {
  // console.log('set meilisearch key and host')
  API_KEY = getInputValue(INPUT_IDS.API)
  HOST = getInputValue(INPUT_IDS.HOST)
})()
export {
  INPUT_IDS,
  INPUT_CLASS,
  onSearchLocations,
  clearSearchBox,
  toggleDropdown,
}